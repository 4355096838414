var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "1000" } },
    [
      _c(
        "v-card",
        { staticClass: "grey lighten-3" },
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", dark: "", color: "#00aff0" } },
            [
              _c("v-toolbar-title", [
                _vm._v(" Edit " + _vm._s(_vm.attr.name) + " ")
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "pa-3 ma-0" },
            [
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c(_vm.attrComponent, {
                        tag: "component",
                        attrs: {
                          attr: _vm.attr,
                          attrvalue: _vm.selected,
                          attrvalues: _vm.attrvalues,
                          "no-focus": ""
                        },
                        on: { "save-attr": _vm.setNewValue }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "mt-6", attrs: { cols: "3" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": true,
                            transition: "scale-transition",
                            "offset-y": "",
                            "nudge-left": "30",
                            "nudge-top": "25",
                            "max-width": "290px",
                            "min-width": "290px"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            outlined: "",
                                            dense: "",
                                            "background-color": "#fff",
                                            label: "Start Date",
                                            clearable: "",
                                            "prepend-inner-icon":
                                              "mdi-calendar",
                                            readonly: "",
                                            value: _vm.formattedStartDate
                                          },
                                          on: {
                                            "click:clear": function($event) {
                                              _vm.startDate = null
                                            }
                                          }
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("v-date-picker", {
                            attrs: { "show-current": "", "no-title": "" },
                            model: {
                              value: _vm.startDate,
                              callback: function($$v) {
                                _vm.startDate = $$v
                              },
                              expression: "startDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "mt-6", attrs: { cols: "3" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": true,
                            transition: "scale-transition",
                            "offset-y": "",
                            "nudge-left": "30",
                            "nudge-top": "25",
                            "max-width": "290px",
                            "min-width": "290px"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            outlined: "",
                                            dense: "",
                                            "background-color": "#fff",
                                            label: "End Date",
                                            clearable: "",
                                            "prepend-inner-icon":
                                              "mdi-calendar",
                                            readonly: "",
                                            value: _vm.formattedEndDate
                                          },
                                          on: {
                                            "click:clear": function($event) {
                                              _vm.endDate = null
                                            }
                                          }
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("v-date-picker", {
                            attrs: { "show-current": "", "no-title": "" },
                            model: {
                              value: _vm.endDate,
                              callback: function($$v) {
                                _vm.endDate = $$v
                              },
                              expression: "endDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _vm.attrvalues.length > 0
            ? _c("v-data-table", {
                attrs: {
                  items: _vm.attrvalues,
                  "single-select": "",
                  "show-select": _vm.hasWriteAccess,
                  headers: _vm.headers,
                  height: "300",
                  "no-data-text": "No Activity to Display",
                  "sort-by": "start_dt",
                  "hide-default-footer": "",
                  dense: ""
                },
                on: { "item-selected": _vm.selectRow },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.status",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm.attrvalue
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    right: "",
                                                    color: _vm.statusColor(item)
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.statusIcon(item)
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.statusTooltip(item)))
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.value",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [_vm._v(_vm._s(_vm.displayValue(item)))])
                        ]
                      }
                    },
                    {
                      key: "item.start_dt",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .moment(item.start_dt)
                                    .format(_vm.$config.date_display)
                                ) +
                                " "
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "item.end_dt",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.end_dt
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .moment(item.end_dt)
                                        .format(_vm.$config.date_display)
                                    ) +
                                    " "
                                )
                              ])
                            : _c("span", [_vm._v(" --- ")])
                        ]
                      }
                    },
                    {
                      key: "item.updated_on",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .moment(item.updated_on)
                                    .format(_vm.$config.date_display)
                                ) +
                                " "
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "item.updated_by",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span", [_vm._v(_vm._s(item.updated_by))])]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            : _vm._e(),
          _c(
            "v-toolbar",
            { staticClass: "grey lighten-3", attrs: { flat: "" } },
            [
              _c("v-spacer"),
              _vm.showDelete
                ? _c(
                    "v-btn",
                    {
                      staticClass: "white--text mr-4",
                      attrs: { color: "red" },
                      on: {
                        click: function($event) {
                          return _vm.openDeleteDialog()
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s("Delete") + " "),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v(" " + _vm._s("mdi-trash-can") + " ")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: "green", disabled: _vm.saveBtnDisabled },
                  on: {
                    click: function($event) {
                      _vm.selected
                        ? _vm.updateAttributeValue()
                        : _vm.saveAttributeValue()
                    }
                  }
                },
                [
                  _vm._v(" " + _vm._s(_vm.selected ? "Update" : "Save") + " "),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.selected
                            ? "mdi-update"
                            : "mdi-content-save-outline"
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450px" },
          model: {
            value: _vm.deleteDialog,
            callback: function($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v("Are you sure you want to delete this item?")
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: { click: _vm.closeDeleteDialog }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { color: "primary" },
                      on: { click: _vm.deleteAttributeValue }
                    },
                    [_vm._v("Confirm")]
                  ),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }